@import '/theme.css';

.component {
  padding-top: sizeLg;
  padding-bottom: sizeX3l;

  & > .logo {
    margin: 0 auto sizeMd;
    width: 315px;
  }

  @media viewportMd {
    padding-top: sizeXl;
    padding-bottom: sizeX5l;
  }
}

.logo {
  color: cBlueDark;
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  list-style-type: none;
  color: cText;
  font-size: fontSizeXs;

  & > :not(:last-child) {
    margin-bottom: sizeX3s;
  }

  @media viewportSm {
    flex-direction: row;

    & > :not(:last-child) {
      margin-bottom: 0;
    }

    & > * {
      margin: 0 calc(sizeLg / 2);

      @media viewportMd {
        margin: 0 calc(sizeX2l / 2);
      }
    }
  }
}
