@import '/theme.css';

.componentHeadingZebra {
  line-height: lineHeightHeading;

  & > * {
    margin: 0;
  }

  & > .title {
    @apply --heading1;
  }

  & > .subtitle {
    @apply --heading1;

    font-weight: fontWeightLight;
  }
}

.componentHeadingXxl {
  @apply --heading1;

  line-height: lineHeightHeading;
}

.componentHeadingXl {
  @apply --heading2;

  line-height: lineHeightHeading;
}

.componentHeadingLg {
  @apply --heading3;

  line-height: lineHeightHeading;
}

.componentHeadingMd {
  @apply --heading4;

  line-height: lineHeightHeading;
}

.componentHeadingSm {
  @apply --heading4;
}

.componentHeadingAlt {
  font-weight: fontWeightBold;
}

.white {
  color: cWhite;
}

.blue {
  color: cBlueDark;
}
