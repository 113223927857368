@import '/theme.css';

.componentBase {
  padding-top: sizeXl;
  padding-bottom: sizeXl;
  scroll-margin-top: calc(headerHeight - 1px);

  @media viewportMd {
    padding-top: sizeX2l;
    padding-bottom: sizeX2l;
  }
}

.heading {
  padding-bottom: 1em;
}
