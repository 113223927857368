@import '/theme.css';

.componentBase {
  padding: 0 gutterS;

  @media viewportMd {
    padding: 0 gutterM;
  }

  @media viewportLg {
    padding: 0 gutterL;
  }

  & > * {
    margin: 0 auto;
  }
}

.componentSm {
  & > * {
    max-width: containerWidthSm;
  }

  @media viewportSm {
    & > * {
      max-width: containerWidthSm;
    }
  }

  @media viewportLg {
    & > * {
      max-width: containerWidthSm;
    }
  }
}

.componentLg {
  & > * {
    max-width: containerWidthLg;
  }

  @media viewportMd {
    & > * {
      max-width: containerWidthLg;
    }
  }

  @media viewportLg {
    & > * {
      max-width: containerWidthLg;
    }
  }
}
